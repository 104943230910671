const { REACT_APP_API_URL, REACT_APP_GHUBEE, REACT_APP_GHUBEE_TOKEN } =
  process.env;

const baseHeader = {
  "Content-Type": "application/json",
};

const baseHeaderToken = {
  ...baseHeader,
  Authorization: "Bearer " + window.localStorage.getItem("token"),
};

const baseHeaderGeHuub = {
  ...baseHeader,
  Authorization: `Bearer ${REACT_APP_GHUBEE_TOKEN}`,
};

export function MountURI(path, data) {
  const url = new URL(`${REACT_APP_API_URL}/${path}`);
  if (Boolean(data) && Boolean(Object.values(data).length))
    url.search = new URLSearchParams(data).toString();
  return url;
}
export function MountURIGHubee(path, data) {
  const url = new URL(`${REACT_APP_GHUBEE}/api/${path}`);
  if (Boolean(data) && Boolean(Object.values(data).length))
    url.search = new URLSearchParams(data).toString();
  return url;
}

export function GET_ALL_GHUBEE(path, data) {
  return {
    url: MountURIGHubee(path, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderGeHuub },
    },
  };
}

export function POST_GHUBEE(path, body) {
  return {
    url: MountURIGHubee(path),
    options: {
      method: "POST",
      headers: { ...baseHeaderGeHuub },
      body: JSON.stringify(body),
    },
  };
}

export function LOGIN_POST_SMS(bodyURL) {
  return {
    url: MountURI(`Account/loginSMSCode?${bodyURL}`),
    options: {
      method: "POST",
      headers: { ...baseHeader },
      body: "",
    },
  };
}

export function LOGIN_POST(body) {
  return {
    url: MountURI("Account/login"),
    options: {
      method: "POST",
      headers: { ...baseHeader },
      body: JSON.stringify(body),
    },
  };
}

export function RECOVER_PASSWORD_GET(data) {
  return {
    url: MountURI("Account/recover-password", data),
    options: {
      method: "GET",
      headers: { ...baseHeader },
    },
  };
}

export function REFRESH_TOKEN(entityId) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Account/refresh-token?entityId=${entityId}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function RESET_PASSWORD_POST(body) {
  return {
    url: MountURI("Account/reset-password"),
    options: {
      method: "POST",
      headers: { ...baseHeader },
      body: JSON.stringify(body),
    },
  };
}

export function PROFILES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Profile", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PROFILES_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Profile"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function FUNCTIONALITY_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Functionality"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function USER_DELETE(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Account", body),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function COMPANY_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Company"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function COMPANY_DELETE(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Company/${data.id}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function COMPANY_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Company/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}
export function COMPANY_GET_ID(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Company", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PAYROLLSETTINGSORGANIZATION_GET(companyId) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`PayrollSettingsOrganization/${companyId}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function ORGANIZATION_POST(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Organization"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(data),
    },
  };
}

export function ORGANIZATION_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Organization"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function ORGANIZATION_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Organization", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function CONTRACTS_GET(organizationId) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Contract/${organizationId}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_CATEGORIES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmployeesCategory/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_CATEGORY_GET(idCategory) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`EmployeesCategory/${idCategory}`),
    employeeCategory: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_CATEGORY_DELETE(idCategory) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`EmployeesCategory/${idCategory}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_CATEGORY_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmployeesCategory"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EMPLOYEE_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Employees/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_CATEGORY_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmployeesCategory"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EMPLOYEES_GET(id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Employees/${id}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Employees"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}
export function EMPLOYEE_POST_IMPORT(file) {
  return {
    url: MountURI("Employees/import"),
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: file,
    },
  };
}

export function EMPLOYEE_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Employees"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EMPLOYEE_DELETE(Id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Employees/${Id}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function VEHICLE_CATEGORIES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("VehicleCategory/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function VEHICLE_CATEGORY_GET(idCategory) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`VehicleCategory/${idCategory}`),
    vehicleCategory: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function VEHICLE_CATEGORY_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("VehicleCategory"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function VEHICLE_CATEGORY_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("VehicleCategory"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function VEHICLE_CATEGORY_DELETE(idCategory) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`VehicleCategory/${idCategory}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function ORGANIZATION_SETTINGS_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("OrganizationSettings"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function ORGANIZATION_SETTINGS_GET(organizationSettingsId) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`OrganizationSettings/${organizationSettingsId}`),
    organizationSettings: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}
export function ORGANIZATION_USER_GET() {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`OrganizationSettings/User`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function ORGANIZATION_SETTINGS_POST(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("OrganizationSettings"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(data),
    },
  };
}

export function LINEHAUL_FLAT_RATES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("LineHaulFlatRate/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function LINEHAUL_FLAT_RATE_GET(idLinehaulFlatRate) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`LineHaulFlatRate/${idLinehaulFlatRate}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function LINEHAUL_FLAT_RATE_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("LineHaulFlatRate"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}
export function LINEHAUL_FLAT_RATE_POST_IMPORT(file) {
  return {
    url: MountURI("LineHaulFlatRate/import"),
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: file,
    },
  };
}

export function LINEHAUL_FLAT_RATE_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("LineHaulFlatRate"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function LINEHAUL_FLAT_RATE_DELETE(idLinehaulFlatRate) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`LineHaulFlatRate/${idLinehaulFlatRate}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function SPOTFLATRATE_GET(idSpotFlatRate) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Spot/${idSpotFlatRate}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function SPOTFLATRATES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Spot/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function SPOTFLATRATE_POST(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Spot"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(data),
    },
  };
}

export function SPOTFLATRATE_PUT(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Spot"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(data),
    },
  };
}

export function SPOTFLATRATE_POST_IMPORT(file) {
  return {
    url: MountURI(`Spot/import`),
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: file,
    },
  };
}

export function SPOTFLATRATE_DELETE(idSpotFlatRate) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Spot/${idSpotFlatRate}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function VEHICLE_GET(id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Vehicle/${id}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function VEHICLES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Vehicle/list`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function VEHICLE_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Vehicle"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function VEHICLE_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Vehicle"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function VEHICLE_DELETE(id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Vehicle/" + id),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function VEHICLE_POST_IMPORT(file) {
  return {
    url: MountURI("Vehicle/import"),
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: file,
    },
  };
}

export function EMPLOYEE_TIMESHEET_GET(id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`EmployeesTimesheet/${id}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_TIMESHEETS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`EmployeesTimesheet`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMPLOYEE_TIMESHEET_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmployeesTimesheet"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EMPLOYEE_TIMESHEET_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmployeesTimesheet"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EMPLOYEE_TIMESHEET_DELETE(id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmployeesTimesheet/" + id),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function FEDEX_CONTACTS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("FedExContact/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function FEDEX_CONTACT_GET(idFedExContact) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`FedExContact/${idFedExContact}`),
    fedExContact: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function FEDEX_CONTACT_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("FedExContact"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function FEDEX_CONTACT_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("FedExContact"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function FEDEX_CONTACT_DELETE(idFedExContact) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`FedExContact/${idFedExContact}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function FEDEX_CONTACT_JOB_TITLE_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("FedExContact/GetJobTitle", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EXTRA_PAYMENT_GET(id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`ExtraPayment/${id}`),
    extraPayment: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EXTRAS_PAYMENTS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`ExtraPayment/list`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EXTRA_PAYMENT_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("ExtraPayment"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EXTRA_PAYMENT_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("ExtraPayment"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EXTRA_PAYMENT_DELETE(idExtraPayment) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`ExtraPayment/${idExtraPayment}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PER_DIEM_GET(id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`PerDiem/${id}`),
    perDiem: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PERS_DIEMS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`PerDiem/list`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PER_DIEM_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("PerDiem"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PER_DIEM_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("PerDiem"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PER_DIEM_DELETE(idPerDiem) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`PerDiem/${idPerDiem}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function ORGANIZATION_LOGO_POST(body) {
  return {
    url: MountURI("Company/logo"),
    options: {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: body,
    },
  };
}

export function GET_ALL(api, data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(api, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function GET(api, id) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`${api}/${id}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function POST(api, body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(api),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PUT(api, body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(api),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function DELETE(api, id, body = {}) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`${api}/${id}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function IMPORT(api, file) {
  return {
    url: MountURI(api),
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: file,
    },
  };
}

export function NOTIFICATION_GET(idNotification) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Notification/${idNotification}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function NOTIFICATIONS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Notification/`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function RULES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Notification/rule/list`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function RULE_DELETE(idRule) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Notification/rule/${idRule}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function RULE_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Notification/rule"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function RULE_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Notification/rule"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function RULE_GET(idRule) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Notification/rule/${idRule}`),
    rule: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMAIL_DISTRIBUTION_LIST_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmailDistribution"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EMAIL_DISTRIBUTION_LIST_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmailDistribution"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function EMAIL_DISTRIBUTION_LIST_DELETE(idEmailDistributionList) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`EmailDistribution/${idEmailDistributionList}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMAIL_DISTRIBUTION_LISTS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("EmailDistribution/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function EMAIL_DISTRIBUTION_LIST_GET(idEmailDistributionList) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`EmailDistribution/${idEmailDistributionList}`),
    emailDistributionList: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function CDL_EXPIRATIONS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("CDLExpiration/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function DOT_EXPIRATIONS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("DOTExpiration/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function ANNUAL_EXPIRATIONS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("AIExpiration/list", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function MAINTENANCE_MONTHLY_RECORD_GET(idMaintenanceMonthlyRecord) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`MonthlyMaintenanceReport/${idMaintenanceMonthlyRecord}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function MAINTENANCE_MONTHLY_RECORDS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`MonthlyMaintenanceReport/list`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function MAINTENANCE_MONTHLY_RECORD_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("MonthlyMaintenanceReport"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function MAINTENANCE_MONTHLY_RECORD_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("MonthlyMaintenanceReport"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function MAINTENANCE_MONTHLY_RECORD_DELETE(idMmr) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`MonthlyMaintenanceReport/${idMmr}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function MAINTENANCES_INTERVAL_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`MaintenanceInterval/list`, data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function MAINTENANCE_INTERVAL_GET(idMaintenanceInterval) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`MaintenanceInterval/${idMaintenanceInterval}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function MAINTENANCE_INTERVAL_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("MaintenanceInterval"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function MAINTENANCE_INTERVAL_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("MaintenanceInterval"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function MAINTENANCE_INTERVAL_DELETE(idMaintenanceInterval) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`MaintenanceInterval/${idMaintenanceInterval}`),
    options: {
      method: "DELETE",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PAYROLL_REPORT_DRIVERS_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/driver", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PAYROLL_REPORT_DRIVER_GET(idDriver) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Payroll/report/driver/${idDriver}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PAYROLL_REPORT_DRIVER_TRIP_GET(idDriver) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Payroll/report/driver/trip/${idDriver}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PAYROLL_REPORT_DRIVER_TRIP_POST(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/driver/trip"),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PAYROLL_REPORT_DRIVER_TRIP_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/driver/trip"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PAYROLL_REPORT_DRIVER_PTO_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/driver/pto"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PAYROLL_REPORT_DRIVER_OTHER_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/driver/other"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PAYROLL_REPORT_EMPLOYEE_GET(idEmployee) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI(`Payroll/report/employee/${idEmployee}`),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PAYROLL_REPORT_EMPLOYEES_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/employee", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}

export function PAYROLL_REPORT_EMPLOYEE_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/employee"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PAYROLL_REPORT_EMPLOYEE_PTO_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/employee/pto"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PAYROLL_REPORT_EMPLOYEE_OTHER_PUT(body) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/employee/other"),
    options: {
      method: "PUT",
      headers: { ...baseHeaderToken },
      body: JSON.stringify(body),
    },
  };
}

export function PAYROLL_REPORT_PENDING_GET(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/report/pending", data),
    options: {
      method: "GET",
      headers: { ...baseHeaderToken },
    },
  };
}
export function PAYROLL_REPORT_RERUN(data) {
  baseHeaderToken.Authorization =
    "Bearer " + window.localStorage.getItem("token");
  return {
    url: MountURI("Payroll/rerunByCloseDate", data),
    options: {
      method: "POST",
      headers: { ...baseHeaderToken },
      // body: JSON.stringify(data),
    },
  };
}
