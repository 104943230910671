/* eslint-disable react/jsx-pascal-case */

import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";

const { REACT_APP_SMS_TIME_TO_EXPIRE } = process.env;

const SmsCode = (params) => {
  const { loginParams, setLoginParams, sendAccessKey, loading } = params;
  const [countSeconds, setCountSeconds] = useState(0);

  useEffect(() => {
    // value default is 60 seconds
    // 300 seconds = 5 minutes
    if (loginParams.smsWasSent) setCountSeconds(REACT_APP_SMS_TIME_TO_EXPIRE);
  }, [loginParams.smsWasSent]);

  useEffect(() => {
    if (countSeconds > 0) {
      setTimeout(() => {
        setCountSeconds(countSeconds - 1);
      }, 1000);
    } else {
      setLoginParams({ ...loginParams, smsWasSent: false });
    }
  }, [loginParams.setSmsWasSent, countSeconds]);

  return (
    <div>
      <p className="text-muted">
        We sent you an access key to {loginParams.phoneNumber} and your e-mail.
      </p>
      <p className="text-muted">
        Enter the 4 digit security code from the text message or e-mail. If you
        did not receive the access key, please request another one.
      </p>
      <CRow className="mb-3">
        <CCol xs="6" className="text-left">
          <CButton
            color={"primary"}
            className="px-4"
            onClick={sendAccessKey}
            disabled={loading || countSeconds > 0}
          >
            Send Access Key
          </CButton>
        </CCol>
        <CCol xs="6" />
      </CRow>
      <CInputGroup className="mb-2">
        <CInputGroupPrepend>
          <CInputGroupText>
            <React.icons.FontAwesomeIcon icon={React.icons.faKey} />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput
          id="code"
          name="code"
          type="text"
          placeholder="Code"
          value={loginParams.smsCode}
          onChange={({ target }) => setLoginParams({ ...loginParams, smsCode: target.value })}
          maxLength={4}
          disabled={loading}
        />
      </CInputGroup>
      <CRow>
        <CCol xs="6">
          {countSeconds > 0 && (
            <p style={{ color: "red" }}>
              {" "}
              The code expired in {Math.floor((countSeconds % 3600) / 60)}:
              {Math.floor(countSeconds % 60)}{" "}
            </p>
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export default SmsCode;
