/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  CRow,
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInputCheckbox,
  CLabel,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import useFetch from "src/hooks/useFetch";
import { LOGIN_POST } from "src/api";
import Welcome from "./Welcome";
import PasswordExpired from "./PasswordExpired";

import { decodeToken } from "src/helpers/functions";

const LoginOnboarding = (props) => {
  const dispatch = useDispatch();

  const { request, error } = useFetch();
  const [loading, setLoading] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const params = new URLSearchParams(props.location.search);
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [remember, setRemember] = React.useState(false);
  const [displayModal, setDisplayModal] = React.useState({
    modal: () => { },
  });

  const [welcome, setWelcome] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState({
    display: true,
    icon: React.icons.faEye,
  });

  const navigate = useHistory();

  async function login(ipUser) {
    const { url, options } = LOGIN_POST({
      userName,
      password,
      companyId: null,
      IpAddress: ipUser,
    });

    const { response, json } = await request(url, options);
    if (response.ok) {
      let timeToExpiredToken = new Date();
      //Clear Storage
      Object.keys(localStorage).forEach((x) => localStorage.removeItem(x));
      //New Storage
      localStorage.setItem("token", json.token);
      localStorage.setItem(
        "expiryToken",
        timeToExpiredToken.getTime() + 24 * 60 * 60 * 1000
      );
      localStorage.setItem("userId", json.userId);
      localStorage.setItem("userName", userName);
      localStorage.setItem("accessCount", json.accessCount);
      localStorage.setItem("version", process.env.REACT_APP_VERSION);

      let jsonPayload = JSON.parse(decodeToken(json.token));

      dispatch({
        type: "set",
        isOnboarding: json.accessCount > 0 ? true : false,
        accessCount: json.accessCount,
        isDriverApp: jsonPayload.profileName === "driver",
      });

      setLoading(false);
      if (json.accessCount !== 0) {
        setWelcome(true);
      } else {
        navigate.push("/");
      }
    }

    if (
      json &&
      json.errors &&
      json.errors.Messages &&
      json.errors.Messages.find((x) => x === "Your password expired.")
    ) {
      displayModal.modal(json.errors.Messages[1], userName);
    }

    setLoading(false);
    setPassword("");
  }

  function handleClickShowPassword(event) {
    event.preventDefault();
    setShowPassword({
      display: !showPassword.display,
      icon: showPassword.display ? React.icons.faEye : React.icons.faEyeSlash
    });
  }

  async function handleClick(event) {
    event.preventDefault();
    clearCache();
    setLoading(true);

    var ipUser = "";

    await fetch(
      "https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0"
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => (ipUser = data.IPv4))
      .catch((error) => console.log(error));

    login(ipUser);
  }

  function clearCache() {
    const cacheCleaner = Object.keys(localStorage);
    for (let i in cacheCleaner) localStorage.removeItem(cacheCleaner[i]);

    dispatch({
      type: "set",
      token: "",
      organizationId: "",
      logoEntity: "",
      userName: "",
    });
  }

  React.useEffect(() => {
    if (params && params.get("success")) {
      setPasswordChanged(true);
    }
  }, [params]);

  return (
    <>
      {welcome ? (
        <Welcome />
      ) : (
        <div className="c-app c-default-layout flex-row align-items-center">
          <CContainer>
            <PasswordExpired
              display={(show) => setDisplayModal({ modal: show })}
            />
            <CRow className="justify-content-center">
              <CCol md="8">
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm>
                        <h1>mygroundforce®</h1>
                        <p className="text-muted">Sign In to your account</p>
                        <CInputGroup className="mb-3">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-user" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            id="input-username-login"
                            type="text"
                            placeholder="Username"
                            autoComplete="username"
                            value={userName}
                            autoFocus
                            onChange={({ target }) => setUserName(target.value)}
                            disabled={loading}
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-4">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-lock-locked" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            id="input-password-login"
                            type={showPassword.display ? "password" : "text"}
                            placeholder="Password"
                            autoComplete="current-password"
                            value={password}
                            onChange={({ target }) => setPassword(target.value)}
                            disabled={loading}
                          />
                          <CInputGroupPrepend>
                            <CInputGroupText onClick={handleClickShowPassword}>
                              <React.icons.FontAwesomeIcon
                                icon={showPassword.icon}
                              />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                        </CInputGroup>
                        <CInputGroup
                          className="mb-4"
                          style={{ paddingLeft: "20px" }}
                        >
                          <CInputGroupPrepend>
                            <CLabel
                              variant="checkbox"
                              className="form-check-label"
                              htmlFor="remember"
                              disabled={loading}
                            >
                              Remember me
                            </CLabel>
                            <CInputCheckbox
                              id="remember"
                              name="remember"
                              checked={remember}
                              onChange={({ target }) =>
                                setRemember(target.checked)
                              }
                              disabled={loading}
                            />
                          </CInputGroupPrepend>
                        </CInputGroup>

                        <CRow>
                          <CCol xs="6" className="text-left">
                            <Link to="/auth/recover-password">
                              <CButton color="link" className="px-0">
                                Forgot password?
                              </CButton>
                            </Link>
                          </CCol>
                          <CCol xs="6" className="text-right">
                            <CButton
                              color={!loading ? "success" : "primary"}
                              className="px-4"
                              onClick={handleClick}
                              disabled={loading || userName.length === 0 || password.length === 0}
                            >
                              {!loading ? "Login" : "Loading..."}
                            </CButton>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                    <CCardFooter className="p-4">
                      {error && error.length > 0 && (
                        <CRow>
                          <CCol>
                            <CAlert color="warning">
                              {error.map((x) => ` ${x}`)}
                            </CAlert>
                          </CCol>
                        </CRow>
                      )}
                      {!error && passwordChanged && (
                        <CRow>
                          <CCol>
                            <CAlert color="success">
                              Your password was successfully reset.
                            </CAlert>
                          </CCol>
                        </CRow>
                      )}
                    </CCardFooter>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div >
      )}
    </>
  );
};

export default LoginOnboarding;
